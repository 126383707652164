<template>
  <div class="p-5 flex flex-column" data-cy="investments">

    <InvestmentTopCard v-if="getPath != '/investments/openbanking'" />

    <spacer-h/>
    <div id="rd3-scrollto"></div>
    <div class="tab-container">

      <TabViewHeaders v-if="!mobile" :headers="headers" @change="onTabHeadersChange" :root-page="'/investments'"/>
      <div v-if="mobile">
        <TabViewDropdown :headers="headers" @change="onTabHeadersChange" :root-page="'/investments'"/>
        <spacer-h/>
      </div>

      <router-view></router-view>
      <!--  render the valuations tab if route is /investments -->
      <TabValuation v-if="isRootPage"/>
      <HelpCard mode="investments" class="pt-5" />

    </div>
    <PerformanceTransactionsModal v-show="showPerformance == 'PerformanceTransactions'"/>
  </div>
</template>
<script>
import SpacerH from '@/components/common/layout/SpacerH.vue'
import {computed, watch, ref, onMounted} from "vue";
import {useStore} from 'vuex'
import {useRoute} from "vue-router";
import PerformanceTransactionsModal from '@/components/modals/PerformanceTransactionsModal.vue'
import TabViewHeaders from "@/components/nav/TabViewHeaders";
import TabValuation from "@/components/tabbed_components/TabValuation";
import InvestmentTopCard from "@/components/top_cards/InvestmentTopCard";
import TabViewDropdown from "@/components/nav/TabViewDropdown";
import HelpCard from "@/components/common/HelpCard";


export default {
  components: {
    TabViewDropdown,
    InvestmentTopCard,
    TabViewHeaders,
    SpacerH,
    PerformanceTransactionsModal,
    TabValuation,
    HelpCard
  },
  setup() {
    const route = useRoute()
    const mobile = ref(false)
    const store = useStore()
    const headers = computed(() => store.getters.getTabHeaders('investments'))
    const screenSize = computed(() => store.getters.getTabView)

    const onTabHeadersChange = (payload) => {
      store.dispatch('setActiveHeader', {page: 'investments', headers: payload})
    }

    const getPath = computed(() => {
      return route.path
    })

    const isRootPage = computed(() => {
      return route.path === '/investments'
    })

    watch(screenSize, (val) => {
      mobile.value = val === 'mobile'
    })

    onMounted(() => {
      mobile.value = screenSize.value === 'mobile'
    })

    return {
      showPerformance: computed(() => store.getters.currentActiveModal),
      headers,
      mobile,
      onTabHeadersChange,
      isRootPage,
      getPath
    }
  },

}
</script>

<style scoped>
button {
  min-width: 220px;
  justify-content: center;
  margin: 5px;
}
</style>
