<template>
    <Dialog class="modal" style="">
        <div class="portfolio-info-modal">
            <div class="flex justify-content-between modal-header">
                <h3 class="header fund-title">Portfolio Summary</h3>
                <Button class="clarity-close-modal-btn" @click="closeModal"><span class="material-icons-round grey">close</span>
                </Button>
            </div>
            <ScrollPanel style="width: 100%; height: 50vh" class="pr-3">
                <div v-for="(type, idx) in sortData" :key="idx">
                    <h5>{{ idx }} Holdings:</h5>
                    <DataTable :value="type" class="rd-table">
                        <Column field="name" header="Name" sortable style="width: 70%">
                            <template #body="slotProps">
                                <h3 class="light-blue">{{ slotProps.data.name }}</h3>
                            </template>
                        </Column>
                        <Column field="total" header="Value" sortable style="width: 30%">
                            <template #body="slotProps">
                                <currency-value :value="slotProps.data.total" :decimal-places="0"/>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </ScrollPanel>
        </div>
    </Dialog>
</template>
<script>
import {computed} from '@vue/reactivity'
import {watch, ref} from 'vue'
import {useStore} from 'vuex'
import api from '@/core/services/api'
import {toDrupalRequest} from '@/core/helpers';
import CurrencyValue from '@/components/common/CurrencyValue.vue'

export default {
    name: 'PortfolioSummaryModal',
    components: {
        CurrencyValue
    },
    props: {
        uuid: String
    },
    setup() {
        const store = useStore()
        const tabledata = ref()
        const portfolio = computed(() => store.getters.fullEditPortfolioSelected)

        const portfolioData = () => {
            const data = []
            const policies = store.state.selections.policyOptions
            if (portfolio) {
                portfolio.value.portfolioplans.forEach(plan => {
                    const policy = policies.find(policy => policy.planstring == plan)
                    if (policy) {
                        data.push({...policy, total: 0})
                        getTotal(plan)
                    }
                })
            }
            tabledata.value = data
        }

        const getTotal = async (plan) => {
            const endpoint = `/valuation/plan/summary/${plan}`;
            const response = await api.get(`${toDrupalRequest(endpoint)}`)

            if (response.total) {
                const idx = tabledata.value.findIndex((i) => {
                    return i.planstring == plan
                })
                if (idx != -1) {
                    tabledata.value[idx].total = response.total
                }
            }
        }

        const sortData = computed(() => {
            const sorted = {}
            if(tabledata.value) {
                const pens = tabledata.value.filter(p => p.productType === 'pens')
                const nonpens = tabledata.value.filter(p => p.productType === 'nonpens')
                const isa = tabledata.value.filter(p => p.productType === 'isa')

                pens.length ? sorted['Pension'] = pens : null
                nonpens.length ? sorted['Non Pension'] = nonpens : null
                isa.length ? sorted['ISA'] = isa : null
            }

            return sorted
        })

        watch(() => portfolio.value, (value) => {
            // check made so doesn't error on deleting portflio as value will be undefined until popped off array elsewhere
            if (value) {
                portfolioData()
            }
        })

        const closeModal = () => {
            store.dispatch('setActiveModal')
        }

        return {
            sortData, closeModal
        }
    }
}
</script>
<style scoped>
.modal:deep(.p-dialog-header) {
    display: none !important;
}

.modal:deep(.p-dialog-content) {
    border-radius: 27px;
}

.fund-info-modal {
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    align-self: center;
}

.fund-title {
    color: var(--clarity-blue);
}

.label {
    font-weight: bold;
    margin-right: 10px;
}

.row {
    padding: 10px;
}

.row:nth-child(odd) {
    background-color: var(--clarity-light-grey);
}

.source {
    color: var(--clarity-grey);
    font-weight: bold;
}
</style>
