<template>
  <div class="p-5 flex flex-column">
    <InvestmentTopCard/>

    <spacer-h/>
    <div id="rd3-scrollto"></div>
    <div>
      <content-card-full>
        <template #title>
          <card-title>
            <template #title><h2>Portfolio Management</h2></template>
          </card-title>
        </template>
        <template #content-full>
          <div class="flex">
            <!-- LEFT -->
            <div class="flex" style="width: 50%;">
              <div class="flex flex-column justify-content-between">
                <div>
                  <h3 class="mb-3">Managing your portfolios</h3>
                  <div class="body pr-5">
                    <p>Welcome to the portfolios section of our website. This page combines the data from your automated data feeds as well as any self-managed holdings you have added to the website. Standard portfolios (all holdings, pension and non-pension) are created by default and the contents of the portfolios are fixed. When viewing the valuation reports on the clarity website you may want to split your investments into multiple portfolios.</p>
                    <p>This page allows you to create your own custom portfolios and set the default reporting portfolio across the website. The benchmark set against your default portfolio will be applied to all portfolios without a specific benchmark. To set / update a specific benchmark please use the buttons provided below.</p></div>
                </div>
                <div class="flex my-5">
                  <Button class="clarity-btn clarity-gradient-orange" @click="openModal">Create a New Portfolio</Button>
                </div>
              </div>
            </div>
            <!-- RIGHT -->
            <div class="flex" style="width: 50%;">
              <div class="portfolioManagementVideo">
                <iframe src="https://player.vimeo.com/video/878609140?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="clarity - Portfolio Management" style="width:100%;height:100%;"></iframe>
              </div>
            </div>
          </div>

          <spacer-h/>

          <div>
            <portfolio-table class="mb-3" :title="standard.title" :data="standard.data" :actions="standard.actions"
                             @setDefault="setDefault" :default="defaultPortfolio" :loading="tableLoading.standard" :isIMS="false" :isDFM="false"/>
            <portfolio-table class="my-6" v-if="dfm.data.length > 0" :title="dfm.title" :data="dfm.data"
                             :actions="dfm.actions" @setDefault="setDefault" :default="defaultPortfolio"
                             :loading="tableLoading.dfm" :isIMS="false" :isDFM="true"/>
            <portfolio-table class="my-6" v-if="ims.data.length > 0" :title="ims.title" :data="ims.data"
                             :actions="ims.actions" @setDefault="setDefault" :default="defaultPortfolio"
                             :loading="tableLoading.ims" :isIMS="true" :isDFM="false"/>
            <portfolio-table class="my-6" :title="custom.title" :data="custom.data" :actions="custom.actions"
                             @setDefault="setDefault" :default="defaultPortfolio" :loading="tableLoading.custom" :isIMS="false" :isDFM="false"/>
          </div>
          <div class="flex justify-content-center">
            <Button class="clarity-btn clarity-gradient-orange" @click="openModal">Create a New Portfolio</Button>
          </div>
        </template>
      </content-card-full>
    </div>
  </div>
  <new-portfolio-modal v-show="showModal === 'newCustomPortfolio'" :mode="modalmode"/>
  <benchmark-modal v-show="showModal === 'setBenchmarkModal'" @closeBenchmarkModal="closeBenchmarkModal" />

  <HelpCard mode="investments" class="p-5 pt-0" />
</template>
<script>
import ContentCardFull from '@/components/common/ContentCardFull.vue'
import CardTitle from '@/components/common/CardTitle.vue'
import SpacerH from '@/components/common/layout/SpacerH.vue'
import PortfolioTable from '@/components/portfolios/PortfolioTable.vue'
import NewPortfolioModal from '@/components/modals/NewPortfolioModal.vue'
import BenchmarkModal from "@/components/modals/BenchmarkModal";
import InvestmentTopCard from "@/components/top_cards/InvestmentTopCard";
import {onMounted, reactive, ref, computed, watch, inject, onBeforeUnmount} from 'vue'
import {useStore} from 'vuex'
import api from '@/core/services/api'
import {toDrupalRequest} from '@/core/helpers';
import HelpCard from "@/components/common/HelpCard";


export default {
  setup() {
    const standard = reactive({
      title: 'Standard Portfolios',
      actions: 'standard',
      data: []
    })

    const custom = reactive({
      title: 'Custom Portfolios',
      actions: 'custom',
      data: []
    })

    const ims = reactive({
      title: 'IMS Portfolios',
      actions: 'standard',
      data: []
    })

    const dfm = reactive({
        title: 'Discretionary Portfolios (DFM)',
        actions: 'standard',
        data: []
    })

    const store = useStore()
    const bus = inject('bus')
    const showModal = computed(() => store.getters.currentActiveModal)
    const modalmode = computed(() => store.getters.activeModalMode)
    const defaultPortfolio = ref()
    const portfolioCount = ref(0)
    const tableLoading = reactive({
      standard: true,
      ims: true,
      dfm: true,
      custom: true
    })

    onMounted(() => {
      // this needs/should be done elsewhere -> awating menu/pages to make use of prior loaded data
      // this view currently works in isolation
      store.dispatch('getPortfolioOptions')
      store.dispatch('getPolicyOptions')
      setTimeout(() => {
        buildDataSet()
        defaultPortfolio.value = store.getters.portfolioSelected
        tableLoading.standard = false
        tableLoading.ims = false
        tableLoading.dfm = false
        tableLoading.custom = false
      }, 2000)
    })

    const setDefault = async (data) => {
      defaultPortfolio.value = data
      // @TODO: implement => store.dispatch('setDefaultPortfolio', data)
      const endpoint = `/valuation/portfolio/default/${data}`;
      const response = await api.get(`${toDrupalRequest(endpoint)}`)
      if (response.error) {
        await store.dispatch('addNotification', {
          severity: 'error',
          summary: 'Set Default Portfolio',
          detail: `'Unable to set default portfolio with uuid: ${data}`
        })
      }
    }

    const buildDataSet = async () => {
      const opts = store.getters.portfolioOptions

      if (opts) {
        opts.forEach(opt => {
          getData(opt)
          if (opt.ims == true) {
              ims.data.push({
                  uuid: opt.uuid,
                  name: opt.name,
                  value: 0
              })
          } else if(opt.dfm == true) {
              dfm.data.push({
                  uuid: opt.uuid,
                  name: opt.name,
                  value: 0
              })
          } else if (['all', 'pens', 'nonpens'].includes(opt.uuid)) {
            standard.data.push({
              uuid: opt.uuid,
              name: opt.name,
              value: 0
            })
          } else {
            custom.data.push({
              uuid: opt.uuid,
              name: opt.name,
              value: 0
            })
          }
        });
      }
      portfolioCount.value = store.getters.portfolioOptions.length
    }

    const getData = async (portfolio) => {
      const res = await store.dispatch('getPortfolioTotal', portfolio.uuid)

      if (portfolio.ims == true) {
          const idx = ims.data.findIndex((e) => {
              return e.uuid == portfolio.uuid
          })
          ims.data[idx].value = res.total
      } else if (portfolio.dfm == true) {
          const idx = dfm.data.findIndex((e) => {
              return e.uuid == portfolio.uuid
          })
          dfm.data[idx].value = res.total
      } else if (['all', 'pens', 'nonpens'].includes(portfolio.uuid)) {
        const idx = standard.data.findIndex((e) => {
          return e.uuid == portfolio.uuid
        })
        standard.data[idx].value = res.total
      } else {
        const idx = custom.data.findIndex((e) => {
          return e.uuid == portfolio.uuid
        })
        custom.data[idx].value = res.total
      }
    }

    const openModal = () => {
      store.dispatch('setEditPortfolioSelected', null)
      store.dispatch('setActiveModal', {modal: 'newCustomPortfolio', mode: 'create'})
    }

    const updatePortfolioTable = (portfolio) => {
      getData(portfolio)

      custom.data.push({
        uuid: portfolio.uuid,
        name: portfolio.name,
        value: 0
      })
      tableLoading.custom = false

      portfolioCount.value = store.getters.portfolioOptions.length
    }

    bus.on('portfolioDeleted', (uuid) => {
      tableLoading.custom = true

      const idx = custom.data.findIndex((e) => {
        return e.uuid == uuid
      })

      custom.data.splice(idx, 1)
      tableLoading.custom = false

      portfolioCount.value = store.getters.portfolioOptions.length
    })

    bus.on('updatedPortfolio', (payload) => {
      tableLoading.custom = true

      const idx = custom.data.findIndex((e) => {
        return e.uuid == payload.uuid
      })
      const full = store.getters.fullEditPortfolioSelected

      custom.data.splice(idx, 1, {...full, value: payload.total})
      tableLoading.custom = false

      portfolioCount.value = store.getters.portfolioOptions.length
    })

    watch(() => store.getters.portfolioOptions, (value) => {
      if (portfolioCount.value != 0 && value.length != portfolioCount.value) {
        value.forEach(portfolio => {
          if (!['all', 'pens', 'nonpens'].includes(portfolio.uuid) && portfolio.ims != true && portfolio.dfm != true) {
            const idx = custom.data.findIndex((e) => {
              return e.uuid == portfolio.uuid
            })

            if (-1 == idx) {
              tableLoading.custom = true
              updatePortfolioTable(portfolio)
            }
          }
        })
      }
    }, {deep: true})

    const closeBenchmarkModal = () => {
      store.dispatch('setActiveModal')
    }

    onBeforeUnmount(() => {
        bus.off('portfolioDeleted')
        bus.off('updatedPortfolio')
    });

    return {
      ims,
      dfm,
      custom,
      standard,
      openModal,
      showModal,
      setDefault,
      defaultPortfolio,
      tableLoading,
      modalmode,
      closeBenchmarkModal
    }
  },
  components: {
    InvestmentTopCard,
    ContentCardFull,
    CardTitle,
    SpacerH,
    PortfolioTable,
    NewPortfolioModal,
    BenchmarkModal,
    HelpCard
  }
}
</script>
<style scoped>
.content-container {
  display: flex;
  flex-direction: column;
  margin: 5px;
}

.content {
  display: flex;
}

.content-body {
  padding-top: 20px;
  padding-bottom: 20px;
}

button {
  min-width: 220px;
  justify-content: center;
  margin: 5px;
}

.card-bottom {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
}

.bottom-title {
  display: flex;
  min-width: max-content;
  padding-right: 5%;
}

.bottom-actions {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.bottom-actions button {
  width: calc(100% / 4);
  padding: 0px 45px;
  margin: 0px 15px;
}

.body {
  font-size: 18px;
  line-height: 28px;
}

.portfolioManagementVideo {
  width: 100%;
  height: auto;
  padding: 20px;
}
</style>
