<template>
    <div>
        <h3 class="my-3">{{ title }}</h3>
        <Skeleton v-if="loading" class="mb-2" height="6rem" borderRadius="16px"></Skeleton>
        <DataTable v-if="!loading" :value="data" columnResizeMode="fit" class="rd-table" :class="{'ims-header': isIMS, 'dfm-header': isDFM}">
            <Column style="width:50%;" field="name" header="Portfolio" key="name" sortable>
                <template #body="slotProps">
                    <h3 class="light-blue">{{ slotProps.data.name }}</h3>
                </template>
            </Column>
            <Column style="width:25%;" field="value" header="Value" key="value" sortable>
                <template #body="slotProps">
                    <currency-value :value="slotProps.data.value" :decimal-places="2" style="font-size: 18px"/>
                </template>
            </Column>
            <Column style="width:25%;" field="actions" header="Actions" key="actions">
                <template #body="slotProps">
                    <div class="flex align-items-center jusify-content-start">
                        <Button v-if="isDefault(slotProps.data.uuid)"
                                class="clarity-btn clarity-gradient-orange set-default"><span
                            class="material-icons-round white mx-2">check_circle</span>Default
                        </Button>
                        <Button v-else @click="setAsDefault(slotProps.data.uuid)"
                                class="clarity-btn clarity-gradient-grey set-default">Set as Default
                        </Button>
                        <div class="flex" style="margin-left: 30px">
              <span class="material-icons-round mx-3 grey" v-tooltip.top="'Portfolio Summary'"
                    @click="toggleModal('portfoliosummary', slotProps.data.uuid)">search</span>
                            <span class="material-icons-round mx-3 grey" v-tooltip.top="(isIMS || isDFM) ? 'View Benchmark' : 'Set Benchmark'"
                                  @click="toggleModal('setBenchmarkModal', slotProps.data.uuid)">bar_chart</span>
                            <div v-if="actions == 'custom'" class="flex align-items-center" style="cursor: pointer;">
                <span class="material-icons-round mx-3 grey" v-tooltip.top="'Update Portfolio'"
                      @click="toggleModal('portfolioedit', slotProps.data.uuid)">edit</span>
                                <span class="material-icons-round mx-3 grey" v-tooltip.top="'Delete Portfolio'"
                                      @click="toggleModal('portfoliodelete', slotProps.data.uuid)">delete_forever</span>
                            </div>
                            <div v-else style="width: 110px"></div>
                        </div>
                    </div>
                </template>
            </Column>
            <template #empty>
                No Portfolios found.
            </template>
            <template #loading>
                Loading records, please wait...
            </template>
        </DataTable>
        <portfolio-summary-modal v-model:visible="showModal.portfoliosummary" :uuid="currentPortfolio" :isIMS="isIMS"/>
        <Dialog v-model:visible="showModal.portfoliodelete" class="delete-dialog" v-if="fullPortfolio">
            <div class="flex justify-content-between modal-header">
                <h3 class="blue">Confirm Delete</h3>
                <Button class="clarity-close-modal-btn" @click="toggleModal"><span
                    class="material-icons-round grey">close</span></Button>
            </div>
            <div class="message">Are you sure you want to delete the portfolio: {{ fullPortfolio ? fullPortfolio.name : '' }}?</div>
            <div class="button-group">
                <Button class="clarity-btn clarity-gradient-grey" @click="toggleModal">Dismiss</Button>
                <Button class="clarity-btn clarity-gradient-orange" @click="confirmDelete">Confirm Action</Button>
            </div>
        </Dialog>
    </div>
</template>
<script>
import api from '@/core/services/api'
import {toRef, reactive, computed, inject, watch} from 'vue'
import {useStore} from 'vuex'
import {toDrupalRequest} from '@/core/helpers';
import CurrencyValue from '@/components/common/CurrencyValue'
import PortfolioSummaryModal from '@/components/modals/PortfolioSummaryModal.vue'

export default {
    props: ['data', 'actions', 'title', 'default', 'loading', 'isIMS', 'isDFM'],
    emits: ['setDefault'],
    setup(props, context) {
        const store = useStore()
        const bus = inject('bus')
        const defaultPortfolio = toRef(props, 'default')
        const showModal = reactive({
            portfoliosummary: false,
            portfoliodelete: false,
            portfolioedit: false
        })

        const activeModal = computed(() => store.getters.currentActiveModal);
        //
        watch(activeModal, (value) => {
            // empty object returned if not set
            if (typeof value == 'object') {
                showModal.portfoliosummary = false
                showModal.portfoliodelete = false
                showModal.portfolioedit = false
            } else {
                showModal[value] = true
            }
        })

        const isDefault = (uuid) => {
            return uuid == defaultPortfolio.value
        }

        const setAsDefault = (uuid) => {
            context.emit('setDefault', uuid)
        }
        const toggleModal = (modal, uuid) => {
            if (uuid) {
                store.dispatch('setEditPortfolioSelected', uuid)
            }
            switch (true) {
                case modal === 'portfolioedit':
                    store.dispatch('setActiveModal', {modal: 'newCustomPortfolio', mode: 'edit'})
                    break;
                case modal === 'portfoliosummary':
                case modal === 'portfoliodelete':
                case modal === 'setBenchmarkModal':
                    store.dispatch('setActiveModal', {modal: modal})
                    break
                default:
                    store.dispatch('setActiveModal')
            }
        }

        const currentPortfolio = computed(() => {
            const selected = store.getters.fullEditPortfolioSelected;
            if(selected) {
                return selected.uuid
            }
            return ''
        })
        const fullPortfolio = computed(() => props.data.find(p => p.uuid === currentPortfolio.value))

        const confirmDelete = async () => {
            const endpoint = '/valuation/portfolios/' + currentPortfolio.value;
            const response = await api.delete(`${toDrupalRequest(endpoint)}`)

            if (response.error) {
                await store.dispatch('addNotification', {
                    severity: 'error',
                    summary: 'Create Custom Portfolio',
                    detail: `Unable to delete portfolio: ${fullPortfolio.value}, with uuid: ${currentPortfolio.value}`
                })
              await store.dispatch('addNotification',{
                    severity: 'error',
                    summary: 'Unable to delete Portfolio',
                    detail: `Custom Portfolio: ${currentPortfolio.value}`,
                    life: 4000
                })
            } else {
                store.dispatch('deleteCustomPortfolio', currentPortfolio.value)
                bus.emit('portfolioDeleted', currentPortfolio.value)
                toggleModal('delete')
                await store.dispatch('addNotification', {
                  severity: 'success',
                  summary: 'Portfolio Deleted',
                  detail: `Custom Portfolio: ${currentPortfolio.value}`,
                  life: 4000
                })
            }
        }

        return {
            isDefault,
            setAsDefault,
            toggleModal,
            showModal,
            currentPortfolio,
            fullPortfolio,
            confirmDelete
        }
    },
    components: {
        CurrencyValue,
        PortfolioSummaryModal
    }
}
</script>
<style scoped>
button.clarity-btn {
    padding: 15px 45px;
}

button.clarity-btn.set-default {
    width: 210px;
    padding: 15px 45px;
}
:deep(.rd-table.dfm-header .p-datatable-table tr th) {
    background: transparent linear-gradient(180deg, #FFD740 0%, #D6AA24 100%) 0% 0% no-repeat padding-box;
}
:deep(.rd-table.ims-header .p-datatable-table tr th) {
    background: transparent linear-gradient(180deg, #7CC2E2 0%, var(--clarity-light-blue) 100%) 0% 0% no-repeat padding-box;
}

</style>
