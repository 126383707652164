<template>
  <Modal>
    <template #body>
      <ContentCardFull>
        <template #title>
          <div class="flex flex-column title">
            <div class="flex justify-content-between align-items-center">
              <h2>Performance Transactions</h2>
              <Button class="clarity-close-modal-btn" @click="closeModal"><span
                  class="material-icons-round grey">close</span></Button>
            </div>
          </div>
        </template>
        <template #content-full>
            <div class="performance-wrapper" align="center">
              <div class="flex justify-content-between pb-3">
                <div class="flex align-items-center">
                  <h3 class="temp-title mr-3">{{ getPlanName(policyBasicInfo) }}</h3>
                  <span class="small-text grey">Owner: {{ getOwnerFromUuid(policyBasicInfo) }} </span>
                </div>
                <div class="flex align-items-center">
                  <pill v-if="policyIsIMS" label="IMS Portfolio" icon="pi pi-check"/>
                  <separator-v v-if="policyIsIMS" class="mx-3"
                               style="border-right-color: var(--clarity-light-grey); height: 24px"/>

                  <img v-if="policyBasicInfo && policyBasicInfo.clarityType === 'clarity'" src="@/assets/logos/clarity-logo-small-blue.svg">
                  <span v-if="policyBasicInfo && policyBasicInfo.clarityType === 'openbanking'" class="material-icons-round blue">account_balance</span>
                  <span v-if="policyBasicInfo && policyBasicInfo.clarityType === 'selfmanagedholding'"
                        class="material-icons-round blue">person</span>
                  <span class="small-text ml-3" style="color: var(--clarity-grey)">{{
                      getPolicyTypeLabel(policyBasicInfo)
                    }}</span>
                </div>
              </div>
              <div class="body clarity-snow-grey">
                <div class="flex align-items-center">
                  <div class="flex-1 text-center p-4">
                    <h3 class="light-blue text-center">Money in</h3>
                    <div class="flex align-items-center justify-content-center">
                      <span class="material-icons-round mr-3">expand_less</span>
                      <h1 class="green text-center">
                        <currency-value :value=" moneyInTotal ?? 0.00" :decimal-places="2"/>
                      </h1>
                      <span class="material-icons-round ml-3">expand_less</span>
                    </div>
                    <p class="small-text text-center grey">Total money in from {{ $date(from, 'DD/MM/YYYY') }} to
                      {{ valuationDate.format('DD/MM/YYYY') ?? to }}</p>
                  </div>
                  <separator-v
                      style="border-right-color: var(--clarity-pure-white); border-right-width: 2px; height: 122px;"/>
                  <div class="flex-1 text-center p-4">
                    <h3 class="light-blue text-center">Money Out</h3>
                    <div class="flex align-items-center justify-content-center">
                      <span class="material-icons-round mr-3">expand_more</span>
                      <h1 class="red text-center">
                        <currency-value :value="moneyOutTotal ?? 0.00" :decimal-places="2"/>
                      </h1>
                      <span class="material-icons-round ml-3">expand_more</span>
                    </div>
                    <p class="small-text text-center grey">Total money out from {{ $date(from, 'DD/MM/YYYY') }} to
                      {{ valuationDate.format('DD/MM/YYYY') ?? to }}</p>
                  </div>
                  <separator-v
                      style="border-right-color: var(--clarity-pure-white); border-right-width: 2px; height: 122px;"/>
                  <div class="flex-1 text-center p-4">
                    <h3 class="light-blue text-center">Net (%)</h3>
                    <h1 class="blue text-center" v-if="typeof goalSeek.percent == 'number' && !isNaN(goalSeek.percent)">
                      {{ $nb(goalSeek.percent, 'percentage', 2) }}</h1>
                    <h1 class="blue text-center" v-else>N/A</h1>
                    <p class="small-text text-center grey">Total net return from {{ $date(from, 'DD/MM/YYYY') }} to
                      {{ valuationDate.format('DD/MM/YYYY') ?? to }}</p>
                  </div>
                </div>
              </div>

              <Button v-if="!showMonthData" @click="toggleMonthData"
                      class="custom-btn more clarity-btn round align-self-center clarity-pure-white"><span
                  class="material-icons-round grey">add</span></Button>
              <Button v-if="showMonthData" @click="toggleMonthData"
                      class="custom-btn less clarity-btn round align-self-center clarity-pure-white"><span
                  class="material-icons-round grey">remove</span></Button>
              <transition name="fade" mode="out-in">
                <div v-show="showMonthData" class="mb-5">
                  <DataTable v-if="goalSeek" :value="goalSeek.months" class="rd-table">
                    <Column header="Month" field="month"/>
                    <Column header="Money In" field="money_in">
                      <template #body="slotProps">
                        <currency-value :value="slotProps.data.money_in" :decimal-places="2"
                                        :class="{ 'positive-text': hasValue(slotProps.data.money_in)}"/>
                      </template>
                    </Column>
                    <Column header="Money Out" field="money_out">
                      <template #body="slotProps">
                        <currency-value :value="slotProps.data.money_out" :decimal-places="2"
                                        :class="{ 'negative-text': hasValue(slotProps.data.money_out)}"/>
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </transition>
              <div>
                <h3 class="mb-3">Money In Transactions</h3>
                <TransactionsTable v-if="moneyIn" :monies="moneyIn" id="transactions-in"></TransactionsTable>
              </div>
              <div>
                <h3 class="mb-3 mt-5">Money Out Transactions</h3>
                <TransactionsTable v-if="moneyOut" :monies="moneyOut" id="transactions-out"></TransactionsTable>
              </div>
            </div>
        </template>
      </ContentCardFull>
    </template>
  </Modal>
</template>
<script>
import Modal from '@/components/common/Modal'
import ContentCardFull from '@/components/common/ContentCardFull'
import Pill from '@/components/nav/Pill.vue'
import SeparatorV from '@/components/common/layout/SeparatorV.vue'
import CurrencyValue from "../common/CurrencyValue";
import {useStore} from 'vuex'
import {computed, ref} from 'vue'
import dayjs from 'dayjs'

import TransactionsTable from '../transactions/TransactionsTable.vue'

export default {
  setup() {
    const store = useStore()
    const showMonthData = ref(false)
    const uuid = computed(() => store.getters.performancePolicySelected)

    const closeModal = () => {
      store.dispatch('setActiveModal')
    }

    const getOwnerFromUuid = (info) => {
      if (info && info.ownership) {
        let rtn = []
        info.ownership.forEach(person => {
          rtn.push(store.getters.personFromUuid(person).name)
        })
        return rtn.join(", ")
      }
    }

    const getPlanName = (info) => {
      return info ? info.name : ''
    }

    const valuationDate = computed(() => {
      let valuation = store.state.valuation.valuationDate
      return new dayjs(valuation)
    })

    const toggleMonthData = () => {
      showMonthData.value = !showMonthData.value
    }

    const origin = computed(() => store.getters.activelModalOrigin)
    const from = origin.value == 'valuation' ? store.state.valuationDate : computed(() => dayjs(store.state.valuation.dateRangeFrom).format('YYYY-MM-DD'))
    const to = origin.value == 'valuation' ? store.state.valuationDate.subtract(1, 'year') : computed(() => dayjs(store.state.valuation.dateRangeTo).format('YYYY-MM-DD'))

    const hasValue = (val) => {
      return val > 0.00
    }

    const moneyIn = computed(() => store.getters.performanceTransactions(uuid.value, 'in'))
    const moneyOut = computed(() => store.getters.performanceTransactions(uuid.value, 'out'))
    const moneyInTotal = computed(() => store.getters.performanceTotal(uuid.value, 'in'))
    const moneyOutTotal = computed(() => store.getters.performanceTotal(uuid.value, 'out'))

    const getPolicyTypeLabel = (policyInfo) => {
      if (!policyInfo || !policyInfo.clarityType) {
        // catch for initial load - prevents error
        return ''
      }

      switch (policyInfo.clarityType) {
        case 'clarity':
          return 'investment'
        case 'selfmanagedholding':
          return 'Self Managed Holding'
        case 'openbanking':
          return 'Open Banking'
      }
    }

    return {
      closeModal,
      getOwnerFromUuid,
      policyBasicInfo: computed(() => store.getters.policyBasicInfo(uuid.value)),
      policyIsIMS: computed(() => store.getters.policyIsIMS(uuid.value)),
      valuationDate,
      getPlanName,
      showMonthData,
      toggleMonthData,
      hasValue,
      moneyIn,
      moneyOut,
      moneyInTotal,
      moneyOutTotal,
      from,
      to,
      goalSeek: computed(() => store.getters.getGoalSeek(uuid.value)),
      getPolicyTypeLabel
    }
  },
  components: {
    Modal,
    ContentCardFull,
    Pill,
    SeparatorV,
    CurrencyValue,
    TransactionsTable
  }
}
</script>
<style scoped>
.title {
  padding: 30px;
  padding-bottom: 0px;
}

.title h2 {
  color: var(--clarity-blue);
}

div:deep(.modal-close) {
  display: none;
}

.body {
  border-radius: 27px;
}

.custom-btn {
  top: -26px; /* half button height */
  border-color: var(--clarity-light-grey);
}

.custom-btn:hover {
  background: var(--clarity-pure-white);
  border-color: var(--clarity-light-grey);
}

.custom-btn:hover span {
  color: var(--clarity-blue);
}

.more:hover:after {
  color: var(--clarity-blue);
  content: 'View Info';
  padding-left: 10px;
}

.less:hover:after {
  color: var(--clarity-blue);
  content: 'View Less';
  padding-left: 10px;
}

.negative-text {
  color: var(--clarity-red);
}

.negative-text:before {
  content: '-';
}

.positive-text {
  color: var(--clarity-green);
}

.positive-text:before {
  content: '+';
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s linear;
}

.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}

.performance-wrapper {
    max-height: 80vh;
    overflow: auto;
    padding-right: 5px;
}
</style>
