<template>
  <Modal id="portfolioModal" :width="`60%`">
    <template #body>
      <ContentCardFull>
        <template #title>
          <div class="flex flex-column title">
            <div class="flex justify-content-between align-items-center" data-cy="portfolio-modal-title">
              <h2 v-if="mode == 'create'">Create a New Portfolio</h2>
              <h2 v-if="mode == 'edit'">Edit Portfolio</h2>
              <Button class="clarity-close-modal-btn" @click="closeModal"><span
                  class="material-icons-round grey">close</span></Button>
            </div>
            <div class="my-2">Create a new portfolio by selecting the holdings you wish to include using the tick boxes below. You can filter the holdings by the person who holds them by using the drop down menu under 'people'. An overview of the portfolio created will then show at the bottom.
            </div>
          </div>
        </template>
        <template #content-full>
          <div class="flex flex-column" style="width: 100%">
            <h3>Portfolio Details</h3>
            <ScrollPanel style="width: 100%; height: 50vh" class="pr-3">
              <div class="flex filters">
                <div class="flex flex-column pr-3">
                  <label>Name</label>
                  <InputText type="text" placeholder="Please specify..." v-model="portfolioName" data-cy="portfolio-modal-name"></InputText>
                </div>
                <div class="flex flex-column pl-3" data-cy="portfolio-modal-people">
                  <label>People:</label>
                  <people-select/>
                </div>
              </div>
              <portfolio-selection v-model:selectedPolicyValue="selectedPolicy" v-model:selectedPlanValue="selectedPlan"
                                   @selected-plan-content="updateHoldingsSummary" data-cy="portfolio-modal-portfolio"/>
              <!-- <div class="flex flex-column overview">
                <h5>Overview</h5>
                <span>Please check details of your new portfolio: </span>
                <div class="creation-summary">
                  <div class="flex align-items-baseline"><label>Name:</label><span>{{ portfolioName }}</span></div>
                  <div class="flex align-items-baseline"><label>People:</label><span>{{ getPeople }}</span></div>

                  <div v-html="holdingsSummary"></div>
                </div>
              </div> -->
            </ScrollPanel>
          </div>
          <div class="flex justify-content-between my-3" style="width: 100%">
            <Button class="clarity-btn clarity-gradient-grey" @click="closeModal">Dismiss</Button>
            <submit-button v-if="mode == 'create'" data-cy="portfolio-modal-submit" style="padding: 0px 45px;" :disabled="!selectedPlan.length || !portfolioName" @submitted="createPortfolio" :loading="loadingState" button-text="Create Portfolio" ></submit-button>
            <submit-button v-if="mode == 'edit'" data-cy="portfolio-modal-submit" style="padding: 0px 45px;" :disabled="!selectedPlan.length || !portfolioName" @submitted="createPortfolio" :loading="loadingState" button-text="Update Portfolio" ></submit-button>
          </div>
        </template>
      </ContentCardFull>
    </template>
  </Modal>
</template>
<script>
import {useStore} from 'vuex'
import {computed, ref, watch, inject} from 'vue'
import Modal from '@/components/common/Modal'
import ContentCardFull from '@/components/common/ContentCardFull'
import PeopleSelect from '@/components/common/filters/PeopleSelect'
import PortfolioSelection from "@/components/portfolios/PortfolioSelection";

import api from '@/core/services/api'
import {toDrupalRequest} from '@/core/helpers';
import SubmitButton from "../common/layout/interactive/SubmitButton";

export default {
  props: ['mode'],
  setup(props) {
    const store = useStore()
    const bus = inject('bus')
    const portfolioName = ref()
    const selectedPolicy = ref([])
    const selectedPlan = ref([])
    const isEditing = ref(false)

    const loadingState = computed(() => store.getters.isLoading('NewPortfolioModal'))

    const holdingsSummary = ref('<div><div class="flex align-items-baseline"><label><b>Pension Holdings:</b></label><span>&nbsp;None</span></div><div class="flex align-items-baseline"><label><b>ISA Holdings:</b></label><span>&nbsp;None</span></div><div class="flex align-items-baseline"><label><b>Non Pension Holdings:</b></label><span>&nbsp;None</span></div></div>');

    watch(() => props.mode, (value) => {
      if (value && value == 'edit') {
        isEditing.value = true
        portfolioName.value = currentPortfolio.value.name
        selectedPlan.value = currentPortfolio.value.portfolioplans
      }
    }, {deep: true})

    const closeModal = () => {
      resetModal()
      store.dispatch('setActiveModal')
    }

    const resetModal = () => {
      portfolioName.value = ''
      selectedPolicy.value = []
      selectedPlan.value = []
      isEditing.value = false
    }

    const createPortfolio = async () => {
      isEditing.value == true ? updatePortfolio() : createNewPortfolio()
    }

    const createNewPortfolio = async () => {

      store.dispatch('setLoading', {id: 'NewPortfolioModal', state: true})

      if(process.env.VUE_APP_DELAY_BACKEND_API) {
        await new Promise(r => setTimeout(r, 2000));
      }

      const endpoint = '/valuation/portfolios';
      const response = await api.post(
          `${toDrupalRequest(endpoint)}`,
          JSON.stringify({name: portfolioName.value, plans: selectedPlan.value}),
          {
            headers: {
              'Content-Type': 'application/json'
            }
          })

      if (response.error) {
        await store.dispatch('addNotification', {severity: 'error',
          summary: 'Create Custom Portfolio',
          detail: `Unable to create portfolio with: ${portfolioName.value}, plans: ${selectedPlan.value}`
        })
      }

      if (response.portfolioID) {
        await store.dispatch('addCustomPortfolio', {
          uuid: response.portfolioID,
          name: portfolioName.value,
          portfolioplans: selectedPlan.value,
          ims: false
        })
        store.dispatch('addNotification',{
          severity: 'success',
          summary: 'Portfolio Created',
          detail: `Custom Portfolio: ${portfolioName.value}`,
          life: 4000
        })
        closeModal()
      }

      store.dispatch('setLoading', {id: 'NewPortfolioModal', state: false})

    }

    const updatePortfolio = async () => {

      store.dispatch('setLoading', {id: 'NewPortfolioModal', state: true})

      if(process.env.VUE_APP_DELAY_BACKEND_API) {
        await new Promise(r => setTimeout(r, 2000));
      }

      const endpoint = '/valuation/portfolios';
      const response = await api.patch(
          `${toDrupalRequest(endpoint + `/${currentPortfolio.value.uuid}`)}`,
          JSON.stringify({name: portfolioName.value, plans: selectedPlan.value}),
          {
            headers: {
              'Content-Type': 'application/json'
            }
          })

      if (response.error) {
        await store.dispatch('addNotification', {severity: 'error',
          summary: 'Update Custom Portfolio',
          detail: `Unable to update portfolio with: ${portfolioName.value}, plans: ${selectedPlan.value}`
        })
      }
      if (response.success == true) {
        // need to fetch 'new' updated portfolio total
        const updated = await store.dispatch('getPortfolioTotal', currentPortfolio.value.uuid)

        store.dispatch('updateCustomPortfolio', {
          uuid: currentPortfolio.value.uuid,
          name: portfolioName.value,
          portfolioplans: selectedPlan.value
        })
        bus.emit('updatedPortfolio', {uuid: currentPortfolio.value.uuid, total: updated.total})
        await store.dispatch('addNotification',{
          severity: 'success',
          summary: 'Portfolio Created',
          detail: `Custom Portfolio: ${portfolioName.value}`,
          life: 4000
        })
        closeModal()
      }

      store.dispatch('setLoading', {id: 'NewPortfolioModal', state: false})
    }

    const getPeople = computed(() => {
      const people = store.getters.peopleSelected
      let rtn = []
      if (!people) {
        return 'Everyone'
      }
      if (people && people.length) {
        people.forEach(person => {
          rtn.push(person.name)
        })
      }

      return rtn.join(', ')
    })

    const currentPortfolio = computed(() => store.getters.fullEditPortfolioSelected)

    const updateHoldingsSummary = (value) => {
      holdingsSummary.value = value;
    }


    return {
      closeModal,
      portfolioName,
      selectedPlan,
      selectedPolicy,
      peopleSelected: computed(() => store.getters.peopleSelected),
      resetModal,
      createPortfolio,
      getPeople,
      currentPortfolio,
      holdingsSummary,
      updateHoldingsSummary,
      loadingState
    }
  },
  components: {
    SubmitButton,
    Modal,
    ContentCardFull,
    PeopleSelect,
    PortfolioSelection
  }
}
</script>
<style scoped>
.title {
  padding: 30px;
  padding-bottom: 0px;
}

div:deep(.modal-close) {
  display: none;
}

.card {
  border-radius: 27px;
  border: 1px solid var(--clarity-light-grey);
  box-shadow: none;
}

.portfolio-item {
  width: 50%;
}

.portfolio-checkbox {
  display: flex;
  width: 60%;
  padding-top: 5px;
  padding-bottom: 5px;
}

div:deep(.p-checkbox) {
  margin-right: 15px;
  border-radius: 2px;
}

div:deep(.p-checkbox-box) {
  border-radius: 0px;
}

.filters {
  width: 100%;
}

.filters > div {
  width: 50%;
}

.filters label {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 18px;
}

.overview {
  font-size: 18px;
}

.creation-summary {
  padding-left: 30px;
}

.creation-summary label {
  font-weight: bold;
  margin-right: 5px;
}

.selected-label {
  color: var(--clarity-blue);
  font-weight: bold;
}

button.clarity-btn {
  padding: 15px 45px;
}
</style>
